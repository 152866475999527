// eslint-disable-next-line
import { getCLS, getFID, getLCP, getFCP, getTTFB } from 'web-vitals';

const pageLoadTime = Date.now();

const reportingEndpointMap = {
  'www.bbc.co.uk':
    'https://europe-west1-bbc-otg-traf-mgr-bq-prod-4591.cloudfunctions.net/report-endpoint',
  'www.bbc.com':
    'https://europe-west1-bbc-otg-traf-mgr-bq-prod-4591.cloudfunctions.net/report-endpoint',
  'www.int.bbc.co.uk':
    'https://europe-west1-bbc-otg-traf-mgr-bq-dev-4105.cloudfunctions.net/report-endpoint',
  'www.test.bbc.co.uk':
    'https://europe-west1-bbc-otg-traf-mgr-bq-dev-4105.cloudfunctions.net/report-endpoint',
  'navigation.int.api.bbc.co.uk':
    'https://europe-west1-bbc-otg-traf-mgr-bq-dev-4105.cloudfunctions.net/report-endpoint',
  'navigation.test.api.bbc.co.uk':
    'https://europe-west1-bbc-otg-traf-mgr-bq-dev-4105.cloudfunctions.net/report-endpoint',
  'local.dev.bbc.co.uk':
    'https://europe-west1-bbc-otg-traf-mgr-bq-dev-4105.cloudfunctions.net/report-endpoint',
};

const reportingEndpoint = reportingEndpointMap[window.location.hostname];
const randomNumber = Math.random();
const sampleRate = 0.01;
const chosen = randomNumber <= sampleRate;

(async function run() {
  // Function scope avoids top-level await which Webpack complains about
  const consent = await window.bbcuser.allowsPerformanceCookies();
  if (reportingEndpoint && navigator.sendBeacon && chosen && consent) {
    const vitals = {
      cls: null,
      fid: null,
      lcp: null,
      fcp: null,
      ttfb: null,
    };

    const update = ({ name, value }) => {
      const vitalName = name.toLowerCase();
      vitals[vitalName] = value;
    };

    getCLS(update, true); // Setting 'true' will report all CLS changes
    getFID(update);
    getLCP(update, true); // Setting 'true' will report all LCP changes
    getFCP(update);
    getTTFB(update);

    window.addEventListener('pagehide', async () => {
      const destination = await window.bbcpage.getDestination();
      const contentType = await window.bbcpage.getContentType();

      const reportingUrl = `${reportingEndpoint}?platform=orbit&owner=${destination}&pageType=${contentType}`;

      const beaconJson = [
        {
          type: 'web-vitals',
          url: window.location.href,
          age: Date.now() - pageLoadTime,
          body: { ...vitals },
        },
      ];

      const beacon = JSON.stringify(beaconJson);

      const headers = { type: 'application/reports+json' };

      const blob = new Blob([beacon], headers);
      navigator.sendBeacon(reportingUrl, blob);
    });
  }
})();
